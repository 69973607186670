<template>
  <div>

    <div class="mt20">
      <el-form :inline="true">
        <el-form-item>
          <el-input v-model="searchObj.companyName" placeholder="请输入企业名称" class="centerInput"></el-input>
        </el-form-item>
        <el-input v-model="searchObj.phone" placeholder="请输入联系电话" class="centerInput"></el-input>
        </el-form-item>
          <el-form-item>
          <el-select v-model="searchObj.registerType" placeholder="注册类型" class="centerSelInput left">
            <el-option v-for="item in regformList" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn ml20" type="button" @click="getTableData(searchPage)">搜索</button>
        </el-form-item>
        <el-form-item>
          <button class="centerBtn resetbtn" type="button" @click="reset">重置</button>
        </el-form-item>
      </el-form>



    </div>
    <Table :data1="tableData" :columns1="columns" :total="total" @getAjax="getTableData" :loading="loading" :page="searchObj.page" :size="searchObj.size"/>



    <!-- 注册企业弹框 -->


    <!-- destroy-on-close  消除开启弹框时自动验证form表单 -->
    <el-drawer :title="isAdd?'注册企业':'审批授权'" :visible.sync="registerQy" direction="rtl" custom-class="demo-drawer"
      ref="drawer" @close="drawClose" :wrapperClosable="isAdd" destroy-on-close>
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form" :validate-on-rule-change="false" v-if="registerType==1">
          <!-- <el-form-item label="企业安全码" :label-width="formLabelWidth" prop="securityCode">
            <el-input v-model="form.securityCode" autocomplete="off" class="centerCzInput" placeholder="请输入"></el-input>
          </el-form-item> -->
          <el-form-item label="企业名称" :label-width="formLabelWidth" prop="companyName">
            <el-input v-model="form.companyName" autocomplete="off" class="centerCzInput" placeholder="请输入" disabled></el-input>
          </el-form-item>
          <el-form-item label="企业地址" :label-width="formLabelWidth" prop="companyAddress">
            <el-input v-model="form.companyAddress" autocomplete="off" class="centerCzInput" placeholder="请输入" disabled>
            </el-input>
          </el-form-item>
          <el-form-item label="法人代表" :label-width="formLabelWidth" prop="legalPerson">
            <el-input v-model="form.legalPerson" autocomplete="off" class="centerCzInput" placeholder="请输入" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系电话" :label-width="formLabelWidth" prop="phone">
            <el-input v-model="form.phone" autocomplete="off" class="centerCzInput" placeholder="请输入" disabled></el-input>
          </el-form-item>
          <el-form-item label="经营范围" :label-width="formLabelWidth" prop="businessNature">
            <el-input v-model="form.businessNature" autocomplete="off" class="centerCzInput" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="已购车型" :label-width="formLabelWidth" prop="carTypeIds">
            <el-checkbox-group v-model="form.carTypeIds"  @change="handleCheckedCitiesChange2" disabled>
              <el-checkbox :label="item.id" name="type" v-for="(item,index) in carTypeList" >
                {{item.typeName}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="业务服务包" :label-width="formLabelWidth" prop="businessServiceIds" >
            <el-checkbox-group v-model="form.businessServiceIds" @change="handleCheckedCitiesChange" disabled>
              <el-checkbox :label="item.id" name="type" v-for="(item,index) in serviceList">{{item.serviceName}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
            <el-form-item label="数据服务包" :label-width="formLabelWidth" prop="dataService">
            <el-checkbox-group v-model="form.dataService" >
              <el-checkbox :label="item.id" name="type" v-for="(item,index) in datetList"  :disabled="dbShow">{{item.serviceName}}
              </el-checkbox>

            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="增值服务包" :label-width="formLabelWidth" prop="incrementServiceIds">
            <el-checkbox-group v-model="form.incrementServiceIds" disabled>
              <el-checkbox v-for="(item,index) in incrementList" name="type" :label="item.id">{{item.serviceName}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="授权账号" :label-width="formLabelWidth" prop="authAccount">
            <el-input v-model="form.authAccount" autocomplete="off" class="centerCzInput" placeholder="请输入"></el-input>

          </el-form-item>

          <el-form-item label="授权菜单" :label-width="formLabelWidth" prop="menuIds">
            <el-cascader @change="cascaderChange" collapse-tags v-model="form.menuIds" :options="menuList"
              class="centerCzSelInput" :props="props" ref="cascader" disabled>
            </el-cascader>
          </el-form-item>

          <el-form-item label="状态" :label-width="formLabelWidth" prop="authStatus">
            <el-switch active-value="1" inactive-value="0" v-model="form.authStatus"></el-switch>
          </el-form-item>

        </el-form>
        <el-form :model="form" :rules="rules2" ref="form" :validate-on-rule-change="false" v-else>

          <el-form-item label="授权账号" :label-width="formLabelWidth" prop="authAccount">
            <el-input v-model="form.authAccount" autocomplete="off" class="centerCzInput" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="状态" :label-width="formLabelWidth" prop="authStatus">
            <el-switch active-value="1" inactive-value="0" v-model="form.authStatus"></el-switch>
          </el-form-item>

        </el-form>

      </div>
      <div class="demo-drawer__footer">
        <el-button class="centerCzbtn mr20" @click="confirmCancellation" :loading="isLoading">{{isAdd?'确认添加':'确认授权'}}</el-button>
        <button class="centerBtn resetbtn" @click="registerQy=false">取消</button>
      </div>
    </el-drawer>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="delConfig">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">取 消</button>
      </span>
    </el-dialog>

        <!-- 拒绝弹框 -->
    <!-- <el-dialog title="拒绝申请" :visible.sync="refuseFirst" width="30%">
      <span>确认拒绝</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="refuseLast=true">确 定</button>
        <button class="centerBtn resetbtn" @click="refuseFirst = false">取 消</button>
      </span>
    </el-dialog> -->

            <!-- 二次拒绝弹框 -->
    <el-dialog title="拒绝申请" :visible.sync="refuseLast" width="30%">
      <span>确认拒绝</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="resClick">确 定</button>
        <button class="centerBtn resetbtn" @click="refuseLast = false">取 消</button>
      </span>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn mr20" @click="resetPwdConfig">确 定</button>
        <button class="centerBtn resetbtn" @click="resetPwd = false">取 消</button>
      </span>
    </el-dialog>


    <!-- 设置权限 -->
    <el-drawer title="设置权限" :visible.sync="jurisdiction" direction="rtl" custom-class="demo-drawer" ref="drawer">
      <div class="demo-drawer__content">
        <el-tree ref="resourcesMenuRef" default-expand-all :data="menuList" show-checkbox node-key="id"
          :default-expanded-keys="[2, 3]" :default-checked-keys="[5]" :props="defaultProps" @check="checkChange"
          auto-expand-parent>
        </el-tree>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn mr20" @click="companyMenu">确认</button>
        <button class="centerBtn resetbtn" @click="jurisdiction=false">取消</button>
      </div>
    </el-drawer>
  </div>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _FindAllPageQy,
  _RefuseAuth,
  _AddCompanyRegister,
  _AuthCompany,
  _AuthUser,
} from "@/api/enterpriseRegister/appliEnterprise";
import {
  _AddCompany,
  _CheckSecurityCode,
  _DeleteCompany,
  _UpdateUserCompanyAuth,
  _UpdateCompany,
  _UpdateUserCompanyPasswordCenter,
  _GetAllMenu,
  _UpdateCompanyMenu,
} from "@/api/enterpriseRegister/enterpriseRegister";
import {
  _CarTypeAll,
  _FindAllPage,
  _incrementsFindAll,
  _CheckUserCompanyPhone,
  _FindAllBusinessService,
  _FindAllDateService,
} from "@/api/selCar";
import { _ReDel } from "@/api/enterpriseRegister/appliEnterprise";
export default {
  components: {
    Table,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        if (isNaN(value)) {
          return callback(new Error("请输入数字"));
        } else {
          if (value.toString().length != 11) {
            return callback(new Error("请输入11位数字"));
          } else {
            callback(); //必须加
          }
        }
      }
    };
    var validateCa = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择授权菜单"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      isLoading: false,
      registerQy: false,
      serviceList: [],
      refuseFirst: false,
      refuseLast: false,
      delDia: false,
      registerType: 1,
      resetPwd: false,
      jurisdiction: false,
      formLabelWidth: "100px",
      carTypeList: [],
      dtList: [],
      rowData: {},
      jurisdictionIdList: [],
      searchObj: {
        companyName: "",
        phone: "",
        page: 1,
        size: 10,
      },
      searchPage:{
        limit: 10,
        page: 1
      },
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      menuList: [],
      menuListIds: [],
      total: 0,
      isAdd: false,
      incrementList: [],
      // checkStrictly:true,
      form: {
        securityCode: "", //企业安全码
        companyName: "", //企业名称
        companyAddress: "", //企业地址
        legalPerson: "", //法人代表
        phone: "", // 联系电话
        businessNature: "", // 经营范围
        carTypeIds: [], // 已购车型
        businessServiceIds: [], //业务服务包
        incrementServiceIds: [], // 增值服务包
        authAccount: "", // 授权账号
        menuIds: [], //授权菜单
        authStatus: "1", //状态
        parentId: "000000",
        id: "",
        dataService: [],
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        legalPerson: [
          {
            required: true,
            message: "请输入法人代表",
            trigger: "change",
          },
        ],
        yylb: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,

            validator: checkPhone,
            trigger: "blur",
          },
        ],
        businessNature: [
          {
            required: true,
            message: "请输入经营范围",
            trigger: "blur",
          },
        ],
        carTypeIds: [
          {
            required: true,
            message: "请选择已购车型",
            trigger: "change",
          },
        ],
        authAccount: [
          {
            required: true,
            message: "请输入授权账号",
            trigger: "blur",
          },
        ],
        menuIds: [
          {
            // required: true,
            // message: '请输入授权菜单',
            // trigger: 'change'
            required: true,
            validator: validateCa,
            trigger: "blur",
          },
        ],
        authStatus: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
      rules2: {
        authAccount: [
          {
            required: true,
            message: "请输入授权账号",
            trigger: "blur",
          },
        ],
        authStatus: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      treeData: [
        {
          id: 1,
          label: "根菜单",
          children: [
            {
              id: 2,
              label: "设备监控",
              children: [
                {
                  id: 7,
                  label: "大屏监控(/screen)",
                },
                {
                  id: 8,
                  label: "园区概览(/location)",
                },
                {
                  id: 9,
                  label: "车辆状态(/vehicle-control)",
                },
                {
                  id: 10,
                  label: "车辆线路关系(/relationship)",
                },
              ],
            },
            {
              id: 3,
              label: "微公交",
            },
            {
              id: 4,
              label: "SV-BUS",
            },
            {
              id: 5,
              label: "配送车(/sharingDelivery)",
            },
            {
              id: 6,
              label: "售卖车(/sharingSale)",
            },
          ],
        },
      ],
      columns: [
        {
          label: "企业名称",
          prop: "companyName",
        },
        {
          label: "企业地址",
          prop: "companyAddress",
        },
        {
          label: "法人代表",
          prop: "legalPerson",
        },
        {
          label: "联系电话",
          prop: "phone",
        },
        {
          label: "已订购车型",
          render: (h, params) => {
            let a = [...params.row.carTypeIds.split(",")];
            var List = "";
            a.forEach((e) => {
              let obj = {};
              obj = this.carTypeList.find((item) => {
                return item.id == e;
              });
              console.log(obj);
              if (typeof obj != "undefined") {
                List += obj.typeName + ",";
              }
            });
             List = List.substring(0, List.length - 1);
            return h("div", {}, List);
          },
        },
        {
          label: "注册类型",
          render: (h, params) => {
            if (params.row.registerType == 1) {
              return h("span", "申请企业注册");
            } else {
              return h("span", "申请试用账号");
            }
          },
        },
        {
          label: "业务服务包",
          // prop: "ywfwb",
          width: 250,
          render: (h, params) => {
            let strYw = "";
            if (params.row.businessServiceList != null) {
              params.row.businessServiceList.forEach((e) => {
                strYw += e.serviceName + ",";
              });
            }
            strYw = strYw.substring(0, strYw.length - 1);

            return h("div", {}, strYw);
          },
        },
        {
          label: "状态",
          render: (h, params) => {
            if (params.row.registerStatus == 1) {
              return h("span", "已授权");
            } else if (params.row.registerStatus == 2) {
              return h("span", "拒绝");
            } else {
              return h("span", "未受理");
            }
          },
        },
        {
          label: "操作",
          // fixed: "right",
          width: 200,
          //0
          render: (h, params) => {
            if (params.row.registerStatus == 0) {
              return h("div", [
                h(
                  "span",
                  {
                    class: "operationA mr20",
                    on: {
                      click: () => {
                        console.log(params.row.registerType);
                        this.isAdd = false;
                        this.registerQy = true;
                        this.rowData = params.row;
                        this.registerType = params.row.registerType;

                        this.form = {
                          // securityCode: params.row.securityCode ? params.row.securityCode : "", //企业安全码
                          companyName: params.row.companyName
                            ? params.row.companyName
                            : "", //企业名称
                          companyAddress: params.row.companyAddress
                            ? params.row.companyAddress
                            : "", //企业地址
                          legalPerson: params.row.legalPerson
                            ? params.row.legalPerson
                            : "", //法人代表
                          phone: params.row.phone ? params.row.phone : "", // 联系电话
                          businessNature: params.row.businessNature
                            ? params.row.businessNature
                            : "", // 经营范围
                          carTypeIds: params.row.carTypeIds
                            ? params.row.carTypeIds.split(",")
                            : [], // 已购车型
                          businessServiceIds: params.row.businessServiceIds
                            ? params.row.businessServiceIds.split(",")
                            : [], //业务服务包
                          incrementServiceIds: params.row.incrementServiceIds
                            ? params.row.incrementServiceIds.split(",")
                            : [], // 增值服务包
                          authAccount: params.row.authAccount
                            ? params.row.authAccount
                            : "", // 授权账号
                          menuIds: params.row.carTypeIds
                            ? params.row.carTypeIds.split(",")
                            : [], //授权菜单
                          authStatus: "1", //状态
                          parentId: "000000",
                          id: params.row.id,
                        };

                        let menuId = [];
                        this.$nextTick(() => {
                          let nodesInfo = this.$refs.cascader.getCheckedNodes();
                          for (
                            let index = 0;
                            index < nodesInfo.length;
                            index++
                          ) {
                            nodesInfo[index].children.forEach((e) => {
                              menuId.push(e.value);
                            });
                          }
                          if (params.row.businessServiceIds.length > 0) {
                            this.form.dataService = this.dtList;
                            this.dbShow = true;
                          }
                          console.log(...this.form.menuIds, ...menuId);
                          let ids2 = "";
                          this.form.dataService.forEach((e) => {
                            let obj2 = {};
                            obj2 = this.menuList.find((item) => {
                              return item.id == e;
                            });
                            if (typeof obj2 != "undefined") {
                              obj2.children.forEach((chilIds) => {
                                ids2 += chilIds.id + ",";
                              });
                            }
                          });
                          if (params.row.carTypeIds.length > 0) {
                            this.dbShow = true;
                            this.form.menuIds = [
                              ...this.form.menuIds,
                              ...menuId,
                              ...this.form.dataService,
                              ...ids2.split(","),
                              "9a96b1d58fd34029b1a2998dec1adcdf",
                              "a04b37e827c047bd9256f65ee908ea21",
                              "b869798c8c71475dad32d194a1f3fc46",
                            ];
                          } else {
                            this.dbShow = false;
                            this.form.dataService = [];
                            this.form.menuIds = [
                              ...this.form.menuIds,
                              ...menuId,
                              ...this.form.dataService,
                              ...ids2.split(","),
                            ];
                          }

                          console.log(menuId);
                          console.log(nodesInfo);
                          console.log(this.form.menuIds);
                        });
                        // this.form.menuIds = [
                        //     ["431ede72641d4699853c62d284bc7545"],
                        //     ["45f451cc97ee4efcb890e211a9bcbbf5"]
                        //   ], //授权菜单
                      },
                    },
                  },
                  "审批授权"
                ),

                h(
                  "span",
                  {
                    class: "operationA",
                    on: {
                      click: () => {
                        this.rowData = params.row;
                        this.refuseLast = true;
                      },
                    },
                  },
                  "拒绝"
                ),
              ]);
            } else {
              return h("div", [
                h(
                  "span",
                  {
                    class: "operationA ",
                    on: {
                      click: () => {
                        this.rowData = params.row;
                        this.delDia = true;
                      },
                    },
                  },
                  "删除"
                ),
              ]);
            }
          },
        },
      ],
      props: {
        checkStrictly: true,
        emitPath: false, //设为一维数组
        multiple: true,
        value: "id",
        label: "menuName",
        children: "children",
      },
      regformList: [
        { id: 1, value: "申请企业注册" },
        { id: 2, value: "申请试用账号" },
      ],
      datetList: [],
      dbShow: false,
      mlist: [],
    };
  },
  mounted() {
    this.getTableData();
    this.getCarType();
    this.getMenuAll();
    this.getIncrementsAll();
    this.businessService();
    this.DateService();
  },
  methods: {
    cascaderChange(value) {
      this.val = value;
      var a = [];
      if (value.length > 0) {
        for (
          var i = 0;
          i < this.$refs.myCascader.getCheckedNodes().length;
          i++
        ) {
          if (this.$refs.myCascader.getCheckedNodes()[i].level == 1) {
            a.push(this.$refs.myCascader.getCheckedNodes()[i].value);
          }
        }
      }
      this.form.carTypeIds = a;
      this.form.businessServiceIds = a;
    },
    handleCheckedCitiesChange(val) {
      if (this.form.carTypeIds.length > val.length) {
        let diff = this.form.carTypeIds.filter(
          (v) => !val.find((item) => item === v)
        );
        this.mlist = diff;
      }
      for (const idx of val) {
        for (const v of this.carTypeList) {
          if (idx === v.businessId) {
            if (this.form.carTypeIds.indexOf(v.id) === -1) {
              this.form.carTypeIds.push(v.id);
              let ids = "";
              val.forEach((e) => {
                let obj = {};
                obj = this.menuList.find((item) => {
                  return item.id == e;
                });
                if (typeof obj != "undefined") {
                  obj.children.forEach((chilIds) => {
                    ids += chilIds.id + ",";
                  });
                }
              });
              console.log(ids);
              ids = ids.substr(0, ids.length - 1);
              if (this.form.carTypeIds.length > this.val.length) {
                this.val = this.form.businessServiceIds;
                this.form.menuIds = [
                  ...this.form.businessServiceIds,
                  ...ids.split(","),
                ];
              }
              this.form.menuIds = [
                ...this.form.businessServiceIds,
                ...ids.split(","),
              ];
              break;
            }
          }
        }
      }
      if (this.form.carTypeIds.length == 0) {
        this.form.businessServiceIds = [];
        this.dbShow = false;
        this.form.dataService = [];
      } else {
        this.form.dataService = this.dtList;
        this.dbShow = true;
      }
    },
    handleCheckedCitiesChange2(val) {
      //勾选关联
      if (this.form.carTypeIds.length == 0) {
        this.form.businessServiceIds = [];
        this.dbShow = false;
        this.form.dataService = [];
      } else {
        for (const idx of val) {
          for (const v of this.serviceList) {
            if (idx === v.id) {
              if (
                this.form.businessServiceIds.indexOf(v.id) === -1 &&
                this.mlist.indexOf(idx) === -1
              ) {
                this.form.businessServiceIds.push(v.id);
                break;
              } else {
                let diff2 = this.form.businessServiceIds.filter((v) =>
                  val.find((item) => item === v)
                );
                this.form.businessServiceIds = diff2;
              }
            }
          }
        }
        this.form.dataService = this.dtList;
        this.dbShow = true;
      }

      let ids = "";
      let ids2 = "";
      val.forEach((e) => {
        let obj = {};
        console.log(e);
        obj = this.menuList.find((item) => {
          return item.id == e;
        });

        if (typeof obj != "undefined") {
          obj.children.forEach((chilIds) => {
            ids += chilIds.id + ",";
          });
        }
      });
      this.form.dataService.forEach((e) => {
        let obj2 = {};
        obj2 = this.menuList.find((item) => {
          return item.id == e;
        });
        if (typeof obj2 != "undefined") {
          obj2.children.forEach((chilIds) => {
            ids2 += chilIds.id + ",";
          });
        }
      });
      if (this.form.dataService.length) {
        this.form.menuIds = [
          ...this.form.carTypeIds,
          ...ids.split(","),
          ...this.form.dataService,
          ...ids2.split(","),
          "9a96b1d58fd34029b1a2998dec1adcdf",
          "a04b37e827c047bd9256f65ee908ea21",
          "b869798c8c71475dad32d194a1f3fc46",
        ];
      } else {
        this.form.menuIds = [
          ...this.form.carTypeIds,
          ...ids.split(","),
          ...this.form.dataService,
          ...ids2.split(","),
        ];
      }
      ids = ids.substr(0, ids.length - 1);
    },
    // 拒绝申请
    async resClick() {
      let { success, message, data } = await _RefuseAuth({
        id: this.rowData.id,
      });
      if (success) {
        this.$message.success(message);
        this.getTableData();
      } else {
        this.$message.error(message);
      }
      this.refuseLast = false;
    },
    checkChange(item, nodes) {
      this.jurisdictionIdList = nodes.checkedKeys;
    },
    async companyMenu() {
      let { success, data, message } = await _UpdateCompanyMenu({
        companyId: this.rowData.id,
        menuIds: this.jurisdictionIdList.join(","),
      });
      if (success) {
        this.$message.success("设置成功");
        this.jurisdiction = false;
        this.reset();
      } else {
        this.$message.error(message);
      }
    },
    //重置密码
    async resetPwdConfig() {
      let { success, data, message } = await _UpdateUserCompanyPasswordCenter({
        companyId: this.rowData.id,
      });
      if (success) {
        this.resetPwd = false;
        this.$message.success("设置成功");
        this.reset();
      } else {
        this.$message.error(error);
      }
    },
    //关闭弹框
    reginsEnterShow() {
      this.isAdd = true;
      this.registerQy = true;
      this.resetForm();
    },
    drawClose() {
      this.$refs.form.clearValidate();
      this.$refs.form.resetField();
    },
    resetForm() {
      this.form = {
        securityCode: "", //企业安全码
        companyName: "", //企业名称
        companyAddress: "", //企业地址
        legalPerson: "", //法人代表
        phone: "", // 联系电话
        businessNature: "", // 经营范围
        carTypeIds: [], // 已购车型
        businessServiceIds: [], //业务服务包
        incrementServiceIds: [], // 增值服务包
        authAccount: "", // 授权账号
        menuIds: [], //授权菜单
        authStatus: "1", //状态
        parentId: "000000",
      };
    },
    // 改变授权状态
    async changeStatus(companyId, authStatus) {
      let { success, data, message } = await _UpdateUserCompanyAuth({
        companyId,
        authStatus,
      });
      if (success) {
        this.$message.success("修改成功");
        this.getTableData();
      } else {
        this.$message.error(message);
      }
    },

    // 删除
    async delConfig() {
      let { success, message, data } = await _ReDel({
        carRegisterId: this.rowData.id,
      });
      if (success == true) {
        this.$message.success("删除成功");
        this.getTableData();
      } else {
        this.getTableData();
        this.$message.error(message);
      }
      this.delDia = false;
    },
    confirmCancellation() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.registerType == 1 ? this.enterpriseAdd() : this.authCompany();
        }
      });
    },
    // 注册企业
    enterpriseAdd() {
      this.isLoading = true;
      this.form.carTypeIds =
        this.form.carTypeIds.length > 0 ? this.form.carTypeIds.join(",") : "";
      this.form.businessServiceIds =
        this.form.businessServiceIds.length > 0
          ? this.form.businessServiceIds.join(",")
          : "";
      this.form.menuIds = Array.from(new Set(this.form.menuIds.flat()));
      this.form.incrementServiceIds =
        this.form.incrementServiceIds.length > 0
          ? this.form.incrementServiceIds.join(",")
          : "";
      this.form.dataService =
        this.form.dataService.length > 0 ? this.form.dataService.join(",") : "";
      _AddCompany(this.form).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success) {
          this.$message.success("授权成功");
          this.registerQy = false;
          this.getTableData();
          this.isLoading = false;
        } else {
          this.form.carTypeIds = [...this.form.carTypeIds.split(",")];
          this.form.businessServiceIds = [
            ...this.form.businessServiceIds.split(","),
          ];
          this.form.incrementServiceIds = [
            ...this.form.incrementServiceIds.split(","),
          ];
          this.form.dataService = [...this.form.dataService.split(",")];
          this.$message.error(res.message);
          this.isLoading = false;
        }
      });
    },

    //type为2
    async authCompany() {
      this.isLoading = true;
      _AuthUser({
        authName: this.form.authAccount,
        authStatus: this.form.authStatus,
        companyId: this.form.id,
      }).then((res) => {
        console.log(res)
        if (!res) {
          this.isLoading = false;
        }
        if (res.success == true) {
          this.$message.success("审批成功");
          this.getTableData();
          this.registerQy = false;
          this.isLoading = false;
        } else {
          this.$message.error(res.message);
          this.isLoading = false;
        }
      });
    },
    // 修改企业 type为2
    async enterpriseUpdate() {
      let newObj = JSON.parse(JSON.stringify(this.form));
      newObj.id = this.rowData.id;
      newObj.carTypeIds = newObj.carTypeIds.join(",");
      newObj.businessServiceIds = newObj.businessServiceIds.join(",");
      newObj.menuIds = Array.from(new Set(newObj.menuIds.flat()));
      newObj.incrementServiceIds = newObj.incrementServiceIds.join(",");
      let { success, data, message } = await _AuthCompany(newObj);
      if (success) {
        this.$message.success("审批成功");
        this.getTableData();
        this.registerQy = false;
      } else {
        this.$message.error(message);
      }
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      let { success, message, data } = await _FindAllPageQy(this.searchObj);
      if (success == true) {
        this.tableData = data.list;
        this.total = data.total;
      }
      this.loading = false;
    },
    reset() {
      this.searchObj = {
        companyName: "",
        phone: "",
        page: 1,
        size: 10,
      };
      this.getTableData();
    },
    // 获取所有车辆类型
    async getCarType() {
      let { success, data, message } = await _CarTypeAll();
      if (success) {
        this.carTypeList = data.list;
      }
    },
    //获取所有菜单
    async getMenuAll() {
      let { success, message, data } = await _GetAllMenu({
        type: 2,
      });
      if (success) {
        this.menuList = data;
      }
    },

    // 查询所有增值服务
    async getIncrementsAll() {
      let { message, data, success } = await _incrementsFindAll();
      console.log(message, success);
      if (success) {
        this.incrementList = data.list;
      } else {
      }
    },

    //查询全部业务服务包
    async businessService() {
      let { success, data, message } = await _FindAllBusinessService();
      if (success) {
        this.serviceList = data.list;
      }
    },
    //查询全部数据服务包
    async DateService() {
      let { message, data, success } = await _FindAllDateService();
      console.log(message, success);
      if (success) {
        this.datetList = data.list;
        for (var a = 0; a < this.datetList.length; a++) {
          this.dtList.push(this.datetList[a].id);
        }
      }
    },
  },
};
</script>

<style scoped>
.left {
  margin-left: 20px;
}
</style>