import service from '@/until/api'


// 申请企业管理页面---分页查询
export function _FindAllPageQy(data) {
    return service({
        url: '/user-nacos/companyRegister/findAllPage',
        method: 'GET',
        params: data
    })
}


//申请企业管理页面---拒绝授权
export function _RefuseAuth(data) {
    return service({
        url: '/user-nacos/companyRegister/refuseAuth',
        method: 'GET',
        params: data
    })
}


//申请企业
export function _AddCompanyRegister(data) {
    return service({
        url: '/user-nacos/companyRegister/addCompanyRegister',
        method: 'POST',
        data
    })
}


//申请企业管理页面---授权企业注册
export function _AuthCompany(data) {
    return service({
        url: '/user-nacos/companyRegister/authCompany',
        method: 'POST',
        data
    })
}



//申请企业管理页面---用户申请授权
export function _AuthUser(data) {
    return service({
        url: '/user-nacos/companyRegister/authUser',
        method: 'POST',
        data
    })
}

//删除
export function _ReDel(data) {
    return service({
        url: '/user-nacos/companyRegister/deleteCompanyRegister',
        method: 'GET',
        params: data
    })
}

