import service from '@/until/api'
// 企业注册管理---分页查询
export function _FindAllPageReg(data) {
    return service({
      url: '/user-nacos/company/findAllPage',
      method: 'GET',
      params: data 
    })
  }

  //中心云--企业注册管理---企业注册
  export function _AddCompany(data) {
    return service({
      url: '/user-nacos/company/addCompany',
      method: 'POST',
      data
    })
  }

  
// 中心云--企业注册管理---校验企业安全码
export function _CheckSecurityCode(data) {
  return service({
    url: '/user-nacos/company/checkSecurityCode',
    method: 'POST',
    data
  })
}


export function _DeleteCompany(data) {
  return service({
    url: '/user-nacos/company/deleteCompany',
    method: 'GET',
    params: data 
  })
}
//编辑企业账号授权

export function _UpdateUserCompanyAuth(data) {
  return service({
    url: '/user-nacos/company/updateAuthStatus',
    method: 'GET',
    params: data 
  })
}


export function _UpdateCompany(data) {
  return service({
    url: '/user-nacos/company/updateCompany',
    method: 'POST',
    data
  })
}


// 中心云端----企业账号重置密码
export function _UpdateUserCompanyPasswordCenter(data) {
  return service({
    url: '/user-nacos/company/updateUserCompanyPassword',
    method: 'GET',
    params: data 
  })
}

//查询所有园区菜单
export function _GetAllMenu(data) {
  return service({
    url: '/system-nacos/menu/getAllMenu',
    method: 'GET',
    params: data 
  })
}


// 中心云端---企业管理---分配权限
export function _UpdateCompanyMenu(data) {
  return service({
    url: '/user-nacos/company/updateCompanyMenu',
    method: 'GET',
    params: data 
  })
}

  
